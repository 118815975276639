import { FC, PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { ReactComponent as CameraFilledIcon } from 'images/newIcons/camera-filled.svg';

import { ScreenCaptureAreaSize, ScreenCapturePoint } from './types';
import { useScreenCapture } from './useScreenCapture';
import { END_ICON_CLASSNAMES, START_ICON_CLASSNAMES } from './utils';

type ScreenCaptureAreaProps = PropsWithChildren<{
  isCapture: boolean;
  onCaptureStart?: (startPoint: ScreenCapturePoint) => void;
  onCaptureEnd?: (
    endPoint: ScreenCapturePoint,
    areaSize: ScreenCaptureAreaSize
  ) => void;
}>;

export const ScreenCaptureArea: FC<ScreenCaptureAreaProps> = ({
  isCapture,
  children,
  onCaptureStart,
  onCaptureEnd,
}) => {
  const captureAreaContainerRef = useRef<HTMLDivElement>(null);
  const captureAreaRef = useRef<HTMLDivElement>(null);

  const {
    startPoint,
    direction,
    handleCaptureStart,
    handleCaptureEnd,
    hadleCaptureCancel,
    handleCaptureMove,
  } = useScreenCapture({
    captureContainerElement: captureAreaContainerRef,
    captureElement: captureAreaRef,
    onCaptureStart: onCaptureStart,
    onCaptureEnd: onCaptureEnd,
  });

  const handleCaptureArea = useMemo(
    () =>
      isCapture
        ? startPoint
          ? handleCaptureEnd
          : handleCaptureStart
        : undefined,
    [isCapture, startPoint, handleCaptureStart, handleCaptureEnd]
  );

  const handleCaptureAreaMove = useMemo(
    () => (isCapture ? handleCaptureMove : undefined),
    [isCapture, handleCaptureMove]
  );

  useEffect(() => {
    if (!isCapture && startPoint) {
      hadleCaptureCancel();
    }
  }, [isCapture, startPoint, hadleCaptureCancel]);

  return (
    <div
      ref={captureAreaContainerRef}
      className="relative w-full h-full"
      onClick={handleCaptureArea}
      onMouseMove={handleCaptureAreaMove}
      onTouchMove={handleCaptureAreaMove}
    >
      {children}
      {isCapture && <div className="absolute left-0 top-0 w-full h-full" />}
      {isCapture && (
        <div
          ref={captureAreaRef}
          className={cn(
            'absolute bg-[#4282E666] border border-dashed border-bright_product',
            !startPoint && 'hidden'
          )}
        >
          <CameraFilledIcon
            className={cn('absolute', START_ICON_CLASSNAMES[direction])}
          />
          <CameraFilledIcon
            className={cn('absolute', END_ICON_CLASSNAMES[direction])}
          />
        </div>
      )}
    </div>
  );
};
