import { useRef } from 'react';
import { MapPreviewRefContext } from 'contexts/map/MapRefContext';
import { Map } from 'mapbox-gl';

export interface MapRefContextProps {
  children: JSX.Element;
}

export const MapPreviewRefProvider = ({ children }: MapRefContextProps) => {
  const mapRef = useRef<Map>(null);

  const mapRefContext = {
    mapRef,
  };

  return (
    <MapPreviewRefContext.Provider value={mapRefContext}>
      {children}
    </MapPreviewRefContext.Provider>
  );
};

export default MapPreviewRefProvider;
