import { circle, lineString, polygon } from '@turf/turf';
import { convertCoordinates } from 'api/converter';
import {
  MGRS_FULL_REGEX,
  SK42_FULL_REGEX,
  WGS_FULL_REGEX,
} from 'constants/map';
import {
  CIRCLE_POLYGON_VERTEX_COUNT,
  VALID_LINE_STRING_MIN_VERTEX_COUNT,
  VALID_POLYGON_MIN_VERTEX_COUNT,
} from 'constants/mapDraw';
import { Position } from 'geojson';
import {
  DrawCircleFeature,
  DrawCircleFeatureProperties,
  DrawCustomFeature,
  DrawFeatureProperties,
} from 'interfaces';
import {
  CoordinateSystems,
  DrawFeatureSubtypes,
  GeometryTypes,
  MapDrawTool,
  MeasureSystems,
} from 'types';
import { AnyObject, TestContext } from 'yup';

import {
  formatWgsCoords,
  getCoordinates,
  getCoordinatesObject,
  getCoordinatesStringFromTuple,
  getCoordinatesTuple,
} from 'utils';

import {
  defaultFormDynamicField,
  defaultValues,
  drawToolSubtypeMap,
} from './constants';
import { FormData, FormDynamicField } from './types';
import { CircleFormData, CommonFormData } from './types';

export function validateVertex(
  this: TestContext<AnyObject>,
  value: string | undefined
) {
  const vertex = value ?? '';
  const lastParentSchema = this?.from?.slice(-1);
  const coordinateSystem = lastParentSchema?.[0]?.value?.coordinateSystem;

  if (coordinateSystem === CoordinateSystems.WGS) {
    return WGS_FULL_REGEX.test(vertex);
  }

  if (coordinateSystem === CoordinateSystems.MGRS) {
    return MGRS_FULL_REGEX.test(vertex);
  }

  if (
    coordinateSystem === CoordinateSystems.SK42 ||
    coordinateSystem === CoordinateSystems.USK2000
  ) {
    return SK42_FULL_REGEX.test(vertex);
  }

  return false;
}

const getDefaultVertexes = (drawFeatureSubtype: DrawFeatureSubtypes) => {
  const minVertexCount =
    (drawFeatureSubtype === DrawFeatureSubtypes.LINE_STRING &&
      VALID_LINE_STRING_MIN_VERTEX_COUNT) ||
    (drawFeatureSubtype === DrawFeatureSubtypes.POLYGON &&
      VALID_POLYGON_MIN_VERTEX_COUNT) ||
    0;

  return new Array(minVertexCount).fill(defaultFormDynamicField);
};

const getValuesFromDrawFeature = (
  drawTool: MapDrawTool,
  drawFeature: DrawCustomFeature | null | undefined
): FormData => {
  const type = drawToolSubtypeMap[drawTool as string] ?? null;

  if (
    drawFeature?.geometry?.type === GeometryTypes.LINE_STRING &&
    drawFeature?.properties?.subtype === DrawFeatureSubtypes.LINE_STRING
  ) {
    const drawFeatureVertexes: FormDynamicField[] =
      drawFeature.geometry.coordinates
        .slice(0, drawFeature.properties.vertexCount)
        .map((coordinates) => ({
          value: formatWgsCoords(coordinates[1], coordinates[0]),
        }));

    const vertexes = [
      ...drawFeatureVertexes,
      ...new Array(
        Math.max(
          VALID_LINE_STRING_MIN_VERTEX_COUNT - drawFeatureVertexes.length,
          0
        )
      ).fill(defaultFormDynamicField),
    ];

    return {
      type: type,
      coordinateSystem: CoordinateSystems.WGS,
      measureSystem: MeasureSystems.METERS,
      vertexes: vertexes,
      centerVertex: '',
      radius: 0,
    } as FormData;
  }

  if (
    drawFeature?.geometry?.type === GeometryTypes.POLYGON &&
    drawFeature?.properties?.subtype === DrawFeatureSubtypes.POLYGON
  ) {
    const drawFeatureVertexes: FormDynamicField[] =
      drawFeature.geometry.coordinates[0]
        .slice(0, drawFeature.properties.vertexCount)
        .map((coordinates) => ({
          value: formatWgsCoords(coordinates[1], coordinates[0]),
        }));

    const vertexes = [
      ...drawFeatureVertexes,
      ...new Array(
        Math.max(VALID_POLYGON_MIN_VERTEX_COUNT - drawFeatureVertexes.length, 0)
      ).fill(defaultFormDynamicField),
    ];

    return {
      type: type,
      coordinateSystem: CoordinateSystems.WGS,
      measureSystem: MeasureSystems.METERS,
      vertexes: vertexes,
      centerVertex: '',
      radius: 0,
    } as FormData;
  }

  if (
    drawFeature?.geometry?.type === GeometryTypes.POLYGON &&
    drawFeature?.properties?.subtype === DrawFeatureSubtypes.CIRCLE
  ) {
    const drawCircleFeature = drawFeature as DrawCircleFeature;
    const centerVertex = formatWgsCoords(
      drawCircleFeature.properties.center[1],
      drawCircleFeature.properties.center[0]
    );
    const radius = +drawCircleFeature.properties.radiusInM.toFixed(2);

    return {
      type: type,
      coordinateSystem: CoordinateSystems.WGS,
      measureSystem: MeasureSystems.METERS,
      vertexes: [],
      centerVertex: centerVertex,
      radius: radius,
    } as FormData;
  }

  return defaultValues;
};

const getInitValues = (drawTool: MapDrawTool) => {
  const type = drawToolSubtypeMap[drawTool as string] ?? null;

  return {
    type: type,
    coordinateSystem: CoordinateSystems.WGS,
    measureSystem: MeasureSystems.METERS,
    vertexes: getDefaultVertexes(type),
    centerVertex: '',
    radius: 0,
  } as FormData;
};

export const convertFormVertex = async (
  prevSystem: CoordinateSystems,
  newSystem: CoordinateSystems,
  vertex: FormDynamicField,
  skipConverting?: boolean
) => {
  const coordinates = getCoordinates(prevSystem, vertex.value);
  const coordinatesObject = getCoordinatesObject(coordinates, prevSystem);

  return skipConverting
    ? { payload: coordinatesObject }
    : convertCoordinates(prevSystem, newSystem, coordinatesObject);
};

export const convertFormVertexes = async (
  prevCoordinateSystem: CoordinateSystems,
  newCoordinateSystem: CoordinateSystems,
  vertexes: FormDynamicField[],
  skipConverting?: boolean
) =>
  Promise.all(
    vertexes.map((vertex) =>
      convertFormVertex(
        prevCoordinateSystem,
        newCoordinateSystem,
        vertex,
        skipConverting
      )
    )
  );

const createDrawCommonFeatureFromFormData = async (
  formData: CommonFormData
) => {
  const convertResult = await convertFormVertexes(
    formData.coordinateSystem,
    CoordinateSystems.WGS,
    formData.vertexes,
    formData.coordinateSystem === CoordinateSystems.WGS
  );

  const coordinates = convertResult.map((coordinates) =>
    getCoordinatesTuple(coordinates.payload, CoordinateSystems.WGS, 'lngLat')
  ) as Position[];

  const feature: DrawCustomFeature | null =
    (formData.type === DrawFeatureSubtypes.LINE_STRING &&
      lineString<DrawFeatureProperties>(coordinates, {
        subtype: formData.type,
        vertexCount: coordinates.length,
        hasMinimumVertexCount:
          coordinates.length >= VALID_LINE_STRING_MIN_VERTEX_COUNT,
      })) ||
    (formData.type === DrawFeatureSubtypes.POLYGON &&
      polygon<DrawFeatureProperties>([[...coordinates, coordinates[0]]], {
        subtype: formData.type,
        vertexCount: coordinates.length,
        hasMinimumVertexCount:
          coordinates.length >= VALID_POLYGON_MIN_VERTEX_COUNT,
      })) ||
    null;

  return feature;
};

const createDrawCircleFeatureFromFormData = async (
  formData: CircleFormData
) => {
  const convertResult = await convertFormVertex(
    formData.coordinateSystem,
    CoordinateSystems.WGS,
    { value: formData.centerVertex },
    formData.coordinateSystem === CoordinateSystems.WGS
  );

  const centerCoordinates = getCoordinatesTuple(
    convertResult.payload,
    CoordinateSystems.WGS,
    'lngLat'
  ) as [number, number];

  const circleFeature: DrawCircleFeature | null = formData.type
    ? circle<DrawCircleFeatureProperties>(centerCoordinates, formData.radius, {
        units: formData.measureSystem,
        steps: CIRCLE_POLYGON_VERTEX_COUNT,
        properties: {
          subtype: formData.type,
          vertexCount: CIRCLE_POLYGON_VERTEX_COUNT,
          hasMinimumVertexCount: true,
          center: centerCoordinates,
          radiusInM:
            formData.measureSystem === MeasureSystems.METERS
              ? formData.radius
              : formData.radius * 1000,
        },
      })
    : null;

  return circleFeature;
};

export const getDefaultValues = (
  drawTool: MapDrawTool,
  drawFeature: DrawCustomFeature | null | undefined
) =>
  drawFeature && drawFeature.properties.vertexCount
    ? getValuesFromDrawFeature(drawTool, drawFeature)
    : getInitValues(drawTool);

export const getConvertedFormVertexes = async (
  prevSystem: CoordinateSystems,
  newSystem: CoordinateSystems,
  vertexes: FormDynamicField[]
) => {
  const convertResult = await convertFormVertexes(
    prevSystem,
    newSystem,
    vertexes
  );

  const newVertexes = convertResult.map((coordinates) => {
    const coordinatesTuple = getCoordinatesTuple(
      coordinates.payload,
      newSystem,
      'lngLat'
    );

    const coordinatesString = getCoordinatesStringFromTuple(
      coordinatesTuple,
      newSystem,
      'lngLat'
    );

    return { value: coordinatesString };
  });

  return newVertexes;
};

export const getConvertedFormCenterVertex = async (
  prevSystem: CoordinateSystems,
  newSystem: CoordinateSystems,
  centerVertex: FormDynamicField
) => {
  const convertResult = await convertFormVertex(
    prevSystem,
    newSystem,
    centerVertex
  );

  const newCenterVertex = getCoordinatesStringFromTuple(
    getCoordinatesTuple(convertResult.payload, newSystem, 'lngLat'),
    newSystem,
    'lngLat'
  );

  return { value: newCenterVertex };
};

// mapDraw works with lngLat coordinates order so it's required to make them latLng when processing
export const createDrawFeatureFromFormData = (formData: FormData) => {
  if (
    formData.type === DrawFeatureSubtypes.LINE_STRING ||
    formData.type === DrawFeatureSubtypes.POLYGON
  ) {
    return createDrawCommonFeatureFromFormData(formData);
  }

  if (formData.type === DrawFeatureSubtypes.CIRCLE) {
    return createDrawCircleFeatureFromFormData(formData);
  }

  return null;
};
