import { FC, memo } from 'react';
import { GEOCODER_POINT, GEOCODER_POLYGON } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { GeocoderFeature } from 'interfaces/geocoder';
import {
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from 'mapbox-gl';
import { geocoderActions } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice';
import { geocoderResultSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice/selectors';
import { LayerPartialSpecification } from 'types';

import { PointSource } from '../PointSource';
import { PolygonSource } from '../PolygonSource';

interface GeocoderResultMarkerProps {
  geocoderResult: GeocoderFeature;
}

const pointMainFillConfig: LayerPartialSpecification<SymbolLayerSpecification> =
  {
    type: 'symbol',
    layout: {
      'icon-image': 'pin-blue-simple',
      'icon-offset': [1, -16],
    },
  };

const polygonMainFillConfig: LayerPartialSpecification<FillLayerSpecification> =
  {
    type: 'fill',
    paint: {
      'fill-color': 'transparent',
    },
  };

const polygonMainLineConfig: LayerPartialSpecification<LineLayerSpecification> =
  {
    type: 'line',
    paint: {
      'line-color': '#053C92',
      'line-width': 1,
    },
  };

const GeocoderResultFeature: FC<GeocoderResultMarkerProps> = memo(
  ({ geocoderResult }) => {
    const dispatch = useAppDispatch();

    const handleClearGeocoderResult = () =>
      dispatch(geocoderActions.setGeocoderResult(null));

    useEscapeDown(handleClearGeocoderResult);

    if (
      geocoderResult.properties.subtype === 'place' ||
      geocoderResult.properties.subtype === 'coordinates'
    ) {
      return (
        <>
          <PointSource
            id={GEOCODER_POINT}
            data={{ type: 'FeatureCollection', features: [geocoderResult] }}
            mainConfig={pointMainFillConfig}
          />
        </>
      );
    }

    if (geocoderResult.properties.subtype === 'square') {
      return (
        <PolygonSource
          id={GEOCODER_POLYGON}
          data={{
            type: 'FeatureCollection' as const,
            features: [geocoderResult.properties.feature],
          }}
          mainFillConfig={polygonMainFillConfig}
          mainLineConfig={polygonMainLineConfig}
        />
      );
    }

    return null;
  }
);

GeocoderResultFeature.displayName = 'GeocoderResultFeature';

export const GeocoderSource = () => {
  const geocoderResult = useAppSelector(geocoderResultSelector);

  if (!geocoderResult) {
    return null;
  }

  return <GeocoderResultFeature geocoderResult={geocoderResult} />;
};
