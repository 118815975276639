import {
  MouseEvent as ReactMouseEvent,
  RefObject,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useState,
} from 'react';
import { INTEGER_REGEX } from 'constants/map';

import {
  ScreenCaptureAreaDirections,
  ScreenCaptureAreaSize,
  ScreenCapturePoint,
} from './types';
import { getMoveCoordinates } from './utils';

interface UseScreenCaptureProps {
  captureContainerElement: RefObject<HTMLDivElement | null>;
  captureElement: RefObject<HTMLDivElement | null>;
  onCaptureStart?: (startPoint: ScreenCapturePoint) => void;
  onCaptureEnd?: (
    endPoint: ScreenCapturePoint,
    areaSize: ScreenCaptureAreaSize
  ) => void;
  onCaptureCancel?: () => void;
}

export const useScreenCapture = ({
  captureContainerElement,
  captureElement,
  onCaptureStart,
  onCaptureEnd,
  onCaptureCancel,
}: UseScreenCaptureProps) => {
  const [startPoint, setStartPoint] = useState<ScreenCapturePoint | null>(null);
  const [direction, setDirection] = useState(
    ScreenCaptureAreaDirections.RIGHT_BOTTOM
  );

  const handleCaptureStart = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!captureContainerElement.current || !captureElement.current) {
        return;
      }

      const rect = captureContainerElement.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const startPoint = { x, y };

      captureElement.current.style.left = `${x}px`;
      captureElement.current.style.top = `${y}px`;
      setStartPoint(startPoint);
      onCaptureStart?.(startPoint);
    },
    [onCaptureStart]
  );

  const handleCaptureEnd = useCallback(() => {
    if (!captureElement.current) {
      return;
    }

    const endPoint = {
      x: Number(captureElement.current.style.left.match(INTEGER_REGEX) ?? 0),
      y: Number(captureElement.current.style.top.match(INTEGER_REGEX) ?? 0),
    };

    const areaSize = {
      width: Number(
        captureElement.current.style.width.match(INTEGER_REGEX) ?? 0
      ),
      height: Number(
        captureElement.current.style.height.match(INTEGER_REGEX) ?? 0
      ),
    };

    setStartPoint(null);
    onCaptureEnd?.(endPoint, areaSize);
  }, [onCaptureEnd]);

  const hadleCaptureCancel = useCallback(() => {
    setStartPoint(null);
    onCaptureCancel?.();
  }, [onCaptureCancel]);

  const handleCaptureMove = useCallback(
    (
      e:
        | ReactMouseEvent<HTMLDivElement, MouseEvent>
        | ReactTouchEvent<HTMLDivElement>
    ) => {
      if (
        !captureContainerElement.current ||
        !captureElement.current ||
        !startPoint
      ) {
        return;
      }

      const rect = captureContainerElement.current.getBoundingClientRect();
      const moveCoordinates = getMoveCoordinates(e);

      const xRel = moveCoordinates.x - rect.left;
      const yRel = moveCoordinates.y - rect.top;
      const direction = `${startPoint.x >= xRel ? 'left' : 'right'}-${
        startPoint.y >= yRel ? 'top' : 'bottom'
      }` as ScreenCaptureAreaDirections;

      setDirection(direction);

      if (xRel < 0 || yRel < 0) {
        return;
      }

      captureElement.current.style.left = `${Math.min(xRel, startPoint.x)}px`;
      captureElement.current.style.top = `${Math.min(yRel, startPoint.y)}px`;
      captureElement.current.style.width = `${Math.abs(xRel - startPoint.x)}px`;
      captureElement.current.style.height = `${Math.abs(
        yRel - startPoint.y
      )}px`;
    },
    [startPoint]
  );

  return {
    startPoint,
    direction,
    handleCaptureStart,
    handleCaptureEnd,
    hadleCaptureCancel,
    handleCaptureMove,
  };
};
