import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';

export function safe<Type>(
  func: () => Type,
  onError?: () => void
): Type | undefined {
  try {
    return func();
  } catch {
    onError && onError();
    return;
  }
}

export const sanitizeFoundIndex = (index: number) =>
  index === -1 ? Number.MAX_SAFE_INTEGER : index;

export const getMonospacedString = (value: string) => `\`${value}\``;

export const arrayIntersection = <T = any>(arr1: T[], arr2: T[]): T[] => {
  const set2 = new Set(arr2);

  return arr1.filter((item) => set2.has(item));
};

export const mergeMaps = <T>(
  oldMap: Record<string, T>,
  newMap: Record<string, T>,
  mergeUnmatchedItems = true,
  mergeMatchedItems: (oldItem: T, newItem: T) => T = (oldItem: T, newItem: T) =>
    newItem
) => {
  const mergedMap = { ...oldMap };

  for (const id in newMap) {
    const oldItem = oldMap[id];
    const newItem = newMap[id];

    if (oldItem) {
      mergedMap[id] = mergeMatchedItems(oldItem, newItem);
    }

    if (!newItem && mergeUnmatchedItems) {
      mergedMap[id] = newItem;
    }
  }

  return mergedMap;
};

export const replaceOrAppendArrayValue = <T>(
  arr: T[],
  value: T,
  compFn: (item: T, value: T) => boolean
) => {
  const res = [...arr];
  const index = arr.findIndex((item) => compFn(item, value));

  if (index === -1) {
    res.push(value);
  } else {
    res.splice(index, 1, value);
  }

  return res;
};

export const isMouseEvent = (
  e: ReactMouseEvent | ReactTouchEvent
): e is ReactMouseEvent => e.type.includes('mouse');

export const isTouchEvent = (
  e: ReactMouseEvent | ReactTouchEvent
): e is ReactTouchEvent => e.type.includes('touch');

export const isMobileDevice = () => {
  return navigator.maxTouchPoints > 0;
};
