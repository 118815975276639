import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { predefinedTemplates, ROOT_HIERARCHY_ID } from 'constants/entities';
import { errorMessages, successMessages } from 'constants/errors';
import { omit } from 'lodash';
import { EntityStorages, MapEntity } from 'types';

import { notify } from 'utils';
import {
  decreaseEntityObjectCounters,
  getMapEntityFullPath,
  increaseEntityObjectCounters,
  moveMapEntity,
  moveMapEntityCounter,
  updateMapEntity,
} from 'utils/entity';
import {
  createHierarchyNode,
  deleteNodeFromHierarchy,
  moveHierarchyNode,
  processHierarchy,
  updateHierarchyProperty,
} from 'utils/hierarchy';

import { LAYERS_REDUCER_NAMES } from '../constants';

import {
  addMediaToEntityThunk,
  deleteEntityThunk,
  getMapEntitiesThunk,
  getMapEntityChildrenThunk,
  getMapFilteredEntitiesThunk,
  getMapInscribedEntities,
  getPredefinedTemplateThunk,
  relinkEntityThunk,
  searchEntityByIdThunk,
  upsertEntityThunk,
} from './actions';
import {
  EntitiesMap,
  EntitiesState,
  MergeEntityFn,
  SetEntitiesMapAction,
  SetEntityCountersMapAction,
  SetHierarchyAction,
  SetSharedEntityIdAction,
  SetStateAction,
  SetTemporaryEntitiesAction,
} from './types';
import {
  getAllChildrenID,
  getEntitiesMap,
  getFilteredEntitiesMap,
  getInitialMapEntity,
} from './utils';

const initialState: EntitiesState = {
  stash: { entitiesMap: {} },
  entitiesMap: {},
  temporaryEntities: [],
  entityCountersMap: {},
  hierarchy: { id: ROOT_HIERARCHY_ID, children: [] },
  predefinedTemplates: {},
  sharedEntityId: '',
};

const mapEntitiesSlice = createSlice({
  name: LAYERS_REDUCER_NAMES.MAP_ENTITIES,
  initialState: initialState,
  reducers: {
    setState(state, action: SetStateAction) {
      return action.payload;
    },
    setEntitiesMap(state, action: SetEntitiesMapAction) {
      state.entitiesMap = action.payload;
    },
    setTemporaryEntities(state, action: SetTemporaryEntitiesAction) {
      state.temporaryEntities = action.payload;
    },
    setEntityCountersMap(state, action: SetEntityCountersMapAction) {
      state.entityCountersMap = action.payload;
    },
    setHierarchy(state, action: SetHierarchyAction) {
      state.hierarchy = action.payload;
    },
    setSharedEntityId(state, action: SetSharedEntityIdAction) {
      state.sharedEntityId = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getMapEntitiesThunk.fulfilled, (state, action) => {
        const { refetch } = action.meta.arg;
        const { entities, hierarchy, counters } = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;

        const mergeMapEntitiesArgs: [EntitiesMap, MergeEntityFn] = [
          state.entitiesMap,
          (prevEntity: MapEntity | undefined, newEntity: MapEntity) => ({
            ...newEntity,
            state: { ...newEntity.state, active: !!prevEntity?.state?.active },
          }),
        ];

        state.entitiesMap = getEntitiesMap(
          entities,
          ROOT_HIERARCHY_ID,
          'initial',
          mapLayerTemplateId,
          mapObjectTemplateId,
          ...((refetch ? mergeMapEntitiesArgs : []) as [
            EntitiesMap,
            MergeEntityFn
          ])
        );

        state.hierarchy = { id: ROOT_HIERARCHY_ID, children: hierarchy ?? [] };
        state.entityCountersMap = {
          ...state.entityCountersMap,
          ...(counters || {}),
        };
      })
      .addCase(getPredefinedTemplateThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.predefinedTemplates[response.template.title] = response;
      })
      .addCase(getPredefinedTemplateThunk.rejected, () => {
        notify.error(errorMessages.GET_PREDEFINED_TEMPLATES_ERROR);
      })
      .addCase(getMapEntitiesThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapFilteredEntitiesThunk.fulfilled, (state, action) => {
        const { filterCriteria, query, idQuery } = action.meta.arg;
        const { entities, hierarchy } = action.payload;
        const isFiltering = !!(filterCriteria?.length || query || idQuery);
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;
        const filteredEntitiesMap = getEntitiesMap(
          entities,
          ROOT_HIERARCHY_ID,
          // Since access_control when filters are provided backend returns full tree!
          EntityStorages.FULL,
          mapLayerTemplateId,
          mapObjectTemplateId
        );

        const { stashedEntitiesMap, entitiesMap } = getFilteredEntitiesMap(
          state.stash.entitiesMap,
          state.entitiesMap,
          filteredEntitiesMap,
          isFiltering
        );

        state.stash = { entitiesMap: stashedEntitiesMap };
        state.entitiesMap = entitiesMap;
        state.hierarchy = { id: ROOT_HIERARCHY_ID, children: hierarchy ?? [] };
      })
      .addCase(getMapFilteredEntitiesThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapInscribedEntities.fulfilled, (state, action) => {
        const { entities } = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;

        const updatedEntitiesMap = getEntitiesMap(
          entities,
          ROOT_HIERARCHY_ID,
          'initial',
          mapLayerTemplateId,
          mapObjectTemplateId,
          state.entitiesMap,
          (oldEntity, newEntity) => {
            if (oldEntity) {
              const entity = { ...oldEntity };

              entity.info.type === 'layer' &&
                (entity.childIDs = newEntity.childIDs);
              entity.info.type === 'object' && (entity.state.active = true);

              return entity;
            } else {
              const entity = {
                ...newEntity,
              };

              entity.info.type === 'object' && (entity.state.active = true);

              return entity;
            }
          }
        );

        state.entitiesMap = { ...state.entitiesMap, ...updatedEntitiesMap };
      })
      .addCase(getMapInscribedEntities.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(searchEntityByIdThunk.fulfilled, (state, action) => {
        const entityId = action.meta.arg;
        const { entities, hierarchy } = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;

        const newEntitiesMap = getEntitiesMap(
          entities,
          ROOT_HIERARCHY_ID,
          'initial',
          mapLayerTemplateId,
          mapObjectTemplateId
        );
        const parents = getMapEntityFullPath(newEntitiesMap, Number(entityId));
        const entity = state.entitiesMap[entityId];
        const parent = entity ? state.entitiesMap[entity.parentIDs[0]] : null;
        const selectedEntity = newEntitiesMap[entityId];
        const newEntitiesMapWithSelectedEntity = {
          ...newEntitiesMap,
          [entityId]: {
            ...selectedEntity,
            state: { ...selectedEntity.state, active: true },
            parentIDs: parent ? [parent.entity.id] : [],
          },
        };

        state.entitiesMap = {
          ...state.entitiesMap,
          ...newEntitiesMapWithSelectedEntity,
        };
        state.hierarchy = updateHierarchyProperty(
          state.hierarchy,
          parents[1],
          'children',
          hierarchy?.at(0) ? hierarchy[0].children : []
        );
      })
      .addCase(searchEntityByIdThunk.rejected, (state, action) => {
        const error = action.payload?.error as AxiosError;

        let response;
        if (error && 'response' in error) {
          response = error.response;
        }

        if (response?.status === 403) {
          notify.error(errorMessages.NO_ACCESS_TO_ENTITY_ERROR);
        } else if (response?.status === 404) {
          notify.error(errorMessages.ENTITY_NOT_FOUND_ERROR);
        } else {
          notify.error(action.payload?.message);
        }
      })
      .addCase(upsertEntityThunk.fulfilled, (state, action) => {
        const { id, parentEntityID } = action.meta.arg;
        const prevEntity = state.entitiesMap[String(id)];
        const entity = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;
        const isCreating = !prevEntity;

        if (isCreating) {
          const parentEntity = state.entitiesMap[String(parentEntityID)];
          const mapEntity = getInitialMapEntity(
            entity,
            mapLayerTemplateId,
            mapObjectTemplateId,
            { ...entity },
            parentEntityID
          );

          mapEntity.state.active = true;

          const updatedEntities = {
            ...state.entitiesMap,
            ...(parentEntity && {
              [String(parentEntityID)]: {
                ...parentEntity,
                childIDs: [...parentEntity.childIDs, entity.id],
              },
            }),
            [String(entity.id)]: mapEntity,
          };

          const updatedHierarchy = processHierarchy(
            state.hierarchy,
            parentEntityID || ROOT_HIERARCHY_ID,
            (hierarchy) => ({
              ...hierarchy,
              children: [...hierarchy.children, createHierarchyNode(entity.id)],
            })
          );

          const updatedCounters = increaseEntityObjectCounters(
            updatedEntities,
            state.entityCountersMap,
            mapEntity.entity.id,
            mapLayerTemplateId,
            mapObjectTemplateId
          );

          state.hierarchy = updatedHierarchy;
          state.entityCountersMap = updatedCounters;
          state.entitiesMap = updatedEntities;
        } else {
          state.entitiesMap = updateMapEntity(state.entitiesMap, entity);
        }

        notify.success(
          prevEntity
            ? successMessages.ENTITY_UPDATE_SUCCESS
            : successMessages.ENTITY_CREATION_SUCCESS
        );
      })
      .addCase(upsertEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(deleteEntityThunk.fulfilled, (state, action) => {
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;
        const deletedEntityID = action.meta.arg;
        const mapEntity = state.entitiesMap[String(deletedEntityID)];
        const updatedEntities = omit(state.entitiesMap, [
          deletedEntityID,
          ...getAllChildrenID(state.entitiesMap, mapEntity),
        ]) as EntitiesMap;

        const updatedHierarchy = deleteNodeFromHierarchy(
          state.hierarchy,
          deletedEntityID,
          mapEntity.parentIDs[0]
        );

        const updatedCounters = decreaseEntityObjectCounters(
          state.entitiesMap,
          state.entityCountersMap,
          mapEntity.entity.id,
          mapLayerTemplateId,
          mapObjectTemplateId
        );

        mapEntity?.parentIDs.length > 0 && (state.hierarchy = updatedHierarchy);
        state.entityCountersMap = updatedCounters;
        state.entitiesMap = updatedEntities;
      })
      .addCase(deleteEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(relinkEntityThunk.fulfilled, (state, action) => {
        const { entityId, oldParentEntityId, newParentEntityId } =
          action.meta.arg;
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;

        const updatedHierarchy = moveHierarchyNode(
          state.hierarchy,
          entityId,
          oldParentEntityId,
          newParentEntityId
        );

        const updatedCounters = moveMapEntityCounter(
          state.entitiesMap,
          state.entityCountersMap,
          entityId,
          oldParentEntityId,
          newParentEntityId,
          mapLayerTemplateId
        );

        const updatedEntitiesMap = moveMapEntity(
          state.entitiesMap,
          entityId,
          oldParentEntityId,
          newParentEntityId
        );

        state.hierarchy = updatedHierarchy;
        state.entityCountersMap = updatedCounters;
        state.entitiesMap = updatedEntitiesMap;
      })
      .addCase(relinkEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(addMediaToEntityThunk.fulfilled, (state, action) => {
        const entity = action.payload;
        state.entitiesMap = updateMapEntity(state.entitiesMap, entity);
      })
      .addCase(addMediaToEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapEntityChildrenThunk.fulfilled, (state, action) => {
        const { parentEntityID, storage, keepState } = action.meta.arg;
        const { entities, hierarchy } = action.payload;
        const { id, children } = hierarchy?.[0] ?? {
          id: ROOT_HIERARCHY_ID,
          children: [],
        };
        const mapLayerTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_LAYER]?.template
            .id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates[predefinedTemplates.MAP_OBJECT]?.template
            .id || 0;
        const currentEntityId = parentEntityID ?? id;
        const entityChildrenMap = getEntitiesMap(
          entities,
          ROOT_HIERARCHY_ID,
          storage,
          mapLayerTemplateId,
          mapObjectTemplateId,
          state.entitiesMap,
          (oldEntity, newEntity) => ({
            ...newEntity,
            state: {
              ...newEntity.state,
              active: !!(keepState ? oldEntity : newEntity)?.state.active,
            },
          })
        );

        state.entitiesMap = {
          ...state.entitiesMap,
          ...entityChildrenMap,
        };

        state.hierarchy = updateHierarchyProperty(
          state.hierarchy,
          currentEntityId,
          'children',
          children
        );
      })
      .addCase(getMapEntityChildrenThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      }),
});

export const { actions: mapEntitiesActions, reducer: mapEntitiesReducer } =
  mapEntitiesSlice;
