import { useContext } from 'react';
import { MapContext, MapPreviewContext } from 'contexts/map/MapContext';
import {
  MapPreviewRefContext,
  MapRefContext,
} from 'contexts/map/MapRefContext';

export const useMap = () => {
  const mapContext = useContext(MapContext);

  if (typeof mapContext === 'undefined') {
    throw new Error('Hook should be called inside MapProvider');
  }

  return mapContext;
};

export const useMapRef = () => {
  const mapRefContext = useContext(MapRefContext);

  if (typeof mapRefContext === 'undefined') {
    throw new Error('Hook should be called inside MapRefProvider');
  }

  return mapRefContext;
};

export const useMapPreview = () => {
  const mapContext = useContext(MapPreviewContext);

  if (typeof mapContext === 'undefined') {
    throw new Error('Hook should be called inside MapProvider');
  }

  return mapContext;
};

export const useMapPreviewRef = () => {
  const mapRefContext = useContext(MapPreviewRefContext);

  if (typeof mapRefContext === 'undefined') {
    throw new Error('Hook should be called inside MapRefProvider');
  }

  return mapRefContext;
};
