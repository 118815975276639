import { useMemo } from 'react';
import { LayerProps } from 'react-map-gl';
import * as turf from '@turf/turf';
import { circleOptions } from 'constants/map';
import { Feature } from 'geojson';
import { useAppSelector } from 'hooks';
import { measurementSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice/selectors';
import { drawToolSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/stateSlice/selectors';
import { MapMeasureTools } from 'types';

import { createFeature } from 'utils';

import { PointSource } from '../PointSource';
import { PolygonSource } from '../PolygonSource';

const DEFAULT_MEASURE_ARTILLERY = {
  name: '',
  range: [],
  position: [0, 0],
};

const artilleryPointMainConfig = {
  type: 'symbol',
  layout: { 'icon-image': 'pin-blue-cannon' },
} as LayerProps;

export const ArtillerySource = () => {
  const drawTool = useAppSelector(drawToolSelector);
  const measurement = useAppSelector(measurementSelector);

  const { name, range, position } =
    measurement?.type === MapMeasureTools.measure_artillery
      ? measurement.value
      : DEFAULT_MEASURE_ARTILLERY;

  const showArtillery =
    drawTool === MapMeasureTools.measure_artillery && name && range;

  const activeArtilleryCircles: Feature[] = useMemo(
    () =>
      range.map((range, idx) => {
        const activeCircle = turf.circle(position, range, circleOptions);

        activeCircle.id = idx;

        return activeCircle;
      }),
    [position, range]
  );

  const artilleryPoint = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: [createFeature({ type: 'Point', coordinates: position }, {})],
    }),
    [position]
  );

  if (!showArtillery) {
    return null;
  }

  return (
    <>
      {activeArtilleryCircles.map((circle) => (
        <PolygonSource
          key={`artillery-circle-${circle.id}`}
          id={`artillery-circle-${circle.id}`}
          data={circle}
        />
      ))}
      <PointSource
        id="artillery-point"
        data={artilleryPoint}
        mainConfig={artilleryPointMainConfig}
      />
    </>
  );
};
