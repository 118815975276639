import { useState } from 'react';
import { ViewState } from 'react-map-gl';
import { googleSatelliteStyle } from 'configs/map/google';
import { MAP_BOX_TOKEN, mapLocale } from 'constants/map';
import { MapPreviewContext } from 'contexts/map/MapContext';
import { MapEvent } from 'mapbox-gl';

interface MapPreviewProviderProps {
  children: JSX.Element;
}

const MapPreviewProvider = ({ children }: MapPreviewProviderProps) => {
  const [viewport, setViewport] = useState<ViewState>({
    latitude: 47.978343,
    longitude: 36.230617,
    zoom: 8,
    bearing: 0,
    pitch: 0,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  });

  const onLoad = (event: MapEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    event.target.setLanguage('ru');
  };

  const mapContext = {
    viewport,
    setViewport,
    defaultConfig: {
      mapboxAccessToken: MAP_BOX_TOKEN,
      locale: mapLocale,
      mapStyle: googleSatelliteStyle,
      onLoad,
      dragRotate: false,
      onMove: () => {},
    },
  };

  return (
    <MapPreviewContext.Provider value={mapContext}>
      {children}
    </MapPreviewContext.Provider>
  );
};

export default MapPreviewProvider;
