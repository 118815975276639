import { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { googleSatelliteStyle } from 'configs/map/google';
import {
  loadVectorMapboxStyle,
  mapboxSatelliteStyle,
} from 'configs/map/mapbox';
import { sovietMilitaryMapStyle } from 'configs/map/sovietMilitaryMap';
import { MAP_BOX_TOKEN, mapLocale } from 'constants/map';
import { MapContext } from 'contexts/map/MapContext';
import { useAppSelector } from 'hooks';
import { MapEvent } from 'mapbox-gl';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export interface MapContextProps {
  children: JSX.Element;
}

export const MapProvider = ({ children }: MapContextProps) => {
  const mapTypes = useAppSelector(typesSelector);
  const lastViewport = localStorage.getItem('viewport');

  const [isTerrain, setTerrain] = useState(false);
  const [isLegend, setLegend] = useState(true);
  const [isMeasure, setMeasure] = useState(false);
  const [viewport, setViewport] = useState<ViewState>(
    lastViewport
      ? JSON.parse(lastViewport)
      : {
          latitude: 47.978343,
          longitude: 36.230617,
          zoom: 8,
          bearing: 0,
          pitch: 0,
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        }
  );

  const onLoad = (event: MapEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    event.target.setLanguage('ru');
  };

  const handleMove = useCallback((evt: ViewStateChangeEvent) => {
    setViewport((viewport) => ({ ...viewport, ...evt.viewState }));
  }, []);

  useEffect(() => {
    setViewport({
      ...viewport,
      bearing: isTerrain ? 41 : 0,
      pitch: isTerrain ? 80 : 0,
    });
  }, [isTerrain]);

  const mapStyle = useMemo(() => {
    if (mapTypes.mapboxSatellite.active) {
      return mapboxSatelliteStyle;
    } else if (mapTypes.mapboxMinimalism.active) {
      return loadVectorMapboxStyle('dark-v11');
    } else if (mapTypes.googleSatellite.active) {
      return googleSatelliteStyle;
    } else if (mapTypes.streetMap.active) {
      return loadVectorMapboxStyle('streets-v12');
    } else if (mapTypes.sovietMilitaryMap.active) {
      return sovietMilitaryMapStyle;
    }
  }, [mapTypes]);

  const mapContext = {
    viewport,
    setViewport,
    isTerrain,
    setTerrain,
    isLegend,
    setLegend,
    isMeasure,
    setMeasure,
    defaultConfig: {
      mapboxAccessToken: MAP_BOX_TOKEN,
      preserveDrawingBuffer: true,
      dragRotate: false,
      locale: mapLocale,
      mapStyle,
      onLoad,
      onMove: handleMove,
    },
  };

  return (
    <MapContext.Provider value={mapContext}>{children}</MapContext.Provider>
  );
};

export default MapProvider;
