import { createContext } from 'react';
import { ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { MapEvent, StyleSpecification } from 'mapbox-gl';
import { TSetState } from 'types';

interface IMapConfig {
  mapboxAccessToken: string;
  locale: { [key: string]: string };
  mapStyle?: string | StyleSpecification | undefined;
  dragRotate: boolean;
  onLoad: (event: MapEvent) => void;
  onMove: (evt: ViewStateChangeEvent) => void;
}

export interface IMapContext {
  viewport: ViewState;
  setViewport: TSetState<ViewState>;
  isTerrain: boolean;
  setTerrain: TSetState<boolean>;
  isLegend: boolean;
  setLegend: TSetState<boolean>;
  isMeasure: boolean;
  setMeasure: TSetState<boolean>;
  defaultConfig: IMapConfig;
}

export const MapContext = createContext<IMapContext | undefined>(undefined);

export interface IMapPreviewContext {
  viewport: ViewState;
  setViewport: TSetState<ViewState>;
  defaultConfig: IMapConfig;
}

export const MapPreviewContext = createContext<IMapPreviewContext | undefined>(
  undefined
);
