import { FC, useEffect, useState } from 'react';
import MapBox from 'react-map-gl';
import cn from 'classnames';
import { FeatureTypes, MAPBOX_PREVIEW_MAP_CONTAINER } from 'constants/map';
import { useMapPreview, useMapPreviewRef } from 'hooks/map';
import { MapEvent } from 'mapbox-gl';
import MapPreviewProvider from 'providers/mapPreview/MapPreviewProvider';
import MapPreviewRefProvider from 'providers/mapPreview/MapPreviewRefProvider';
import { GeometryNoCollection } from 'types';

import {
  createFeature,
  getAdjustedBounds,
  prepareFeatureCollection,
} from 'utils';
import { getBoundsFromFeatureCollection } from 'utils/entity';

import { BoundaryGridSource } from '../Sources/BoundaryGridSource';
import { GoogleLabelsSource } from '../Sources/GoogleLabelsSource';
import { GoogleSatelliteSource } from '../Sources/GoogleSatelliteSource';

import { PreviewMapObjectsSource } from './PreviewMapObjectsSource';

type FeatureProps = GeoJSON.GeoJsonProperties & { name?: string };
type Feature = GeoJSON.Feature<GeometryNoCollection, FeatureProps>;

interface PreviewMapProps {
  featureCollection: GeoJSON.FeatureCollection<
    GeometryNoCollection,
    FeatureProps | null
  >;
  className: string;
}

export const PreviewMap: FC<PreviewMapProps> = (props) => (
  <MapPreviewRefProvider>
    <MapPreviewProvider>
      <PreviewMapInner {...props} />
    </MapPreviewProvider>
  </MapPreviewRefProvider>
);

export const PreviewMapInner: FC<PreviewMapProps> = ({
  featureCollection,
  className,
}) => {
  const { defaultConfig, setViewport, viewport } = useMapPreview();
  const { mapRef } = useMapPreviewRef();

  const [pointFeatures, setPoints] = useState<GeoJSON.Feature[]>([]);
  const [lineFeatures, setLines] = useState<GeoJSON.Feature[]>([]);
  const [polygonFeatures, setPolygons] = useState<GeoJSON.Feature[]>([]);
  const [foundBounds, setFoundBounds] = useState<boolean>();

  const constructPolygon = ({ geometry, properties }: Feature) =>
    createFeature(geometry, {
      title: properties.name,
      width: 5,
      lineColor: '#0047AB',
      fillColor: '#7DF9FF',
      opacity: 0.7,
    });

  const constructLine = ({ geometry, properties }: Feature) =>
    createFeature(geometry, {
      title: properties.name,
      width: 5,
      lineColor: '#FF4D00',
      opacity: 0.7,
    });

  const constructPoint = ({ geometry, properties }: Feature) =>
    createFeature(geometry, {
      ...properties,
      title: properties.name,
    });

  useEffect(() => {
    foundBounds && setFoundBounds(false);
    const points: GeoJSON.Feature[] = [];
    const lines: GeoJSON.Feature[] = [];
    const polygons: GeoJSON.Feature[] = [];

    featureCollection.features.map((feature) => {
      switch (feature.geometry.type) {
        case FeatureTypes.POINT:
          points.push(constructPoint(feature as Feature));
          break;
        case FeatureTypes.LINE:
          lines.push(constructLine(feature as Feature));
          break;
        case FeatureTypes.POLYGON:
          polygons.push(constructPolygon(feature as Feature));
          break;
      }
    });

    setPoints(points);
    setLines(lines);
    setPolygons(polygons);
  }, [featureCollection]);

  useEffect(() => {
    if (!foundBounds && mapRef.current) {
      const initialBounds = getBoundsFromFeatureCollection(featureCollection);
      const adjustedBounds = getAdjustedBounds(initialBounds, mapRef.current, {
        left: 16,
        down: 16,
        right: 16,
        up: 16,
      });
      const { lng, lat } = adjustedBounds.getCenter();
      const zoom = mapRef.current?.cameraForBounds(adjustedBounds)?.zoom;

      setViewport({
        ...viewport,
        zoom: zoom ?? viewport.zoom,
        longitude: lng,
        latitude: lat,
      });
      setFoundBounds(true);
    }
  }, [mapRef.current, foundBounds]);

  const handleLoad = (event: MapEvent) => {
    defaultConfig.onLoad(event);
    setFoundBounds(false);
  };

  return (
    <div
      id={MAPBOX_PREVIEW_MAP_CONTAINER}
      className={cn(className, { invisible: !foundBounds })}
    >
      <MapBox
        {...defaultConfig}
        {...viewport}
        onLoad={handleLoad}
        ref={(ref) => (mapRef.current = ref && ref.getMap())}
        projection={{ name: 'mercator' }}
        interactive={false}
      >
        <GoogleLabelsSource />
        <BoundaryGridSource />
        <GoogleSatelliteSource />
        <PreviewMapObjectsSource
          points={prepareFeatureCollection(pointFeatures)}
          lines={prepareFeatureCollection(lineFeatures)}
          polygons={prepareFeatureCollection(polygonFeatures)}
        />
      </MapBox>
    </div>
  );
};
