import { IMediaFile } from 'interfaces';
import { ImageOptions, jsPDF } from 'jspdf';

import { sanitizeFoundIndex } from './common';
import { isVideo } from './monitoring';

const getCanvasSnapshotBlob = async (
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: any
) => {
  const imageBlob = await new Promise<Blob | null>((resolve) =>
    canvas.toBlob(resolve, type, quality)
  );

  return imageBlob;
};

const getImageElementBlobContent = async (
  image: HTMLImageElement,
  x: number,
  y: number,
  width: number,
  height: number,
  pixelRatio = 1
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;
  canvas.style.width = width + 'px';
  canvas.style.height = height + 'px';

  context?.scale(pixelRatio, pixelRatio);
  context?.drawImage?.(
    image,
    x * pixelRatio,
    y * pixelRatio,
    width * pixelRatio,
    height * pixelRatio,
    0,
    0,
    width,
    height
  );

  return getCanvasSnapshotBlob(canvas);
};

const getPdfContentAlignedSizes = (
  pageWidth: number,
  pageHeight: number,
  contentWidth: number,
  contentHeight: number
) => {
  const widthRatio = pageWidth / contentWidth;
  const heightRatio = pageHeight / contentHeight;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  const canvasWidth = contentWidth * ratio;
  const canvasHeight = contentHeight * ratio;

  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = (pageHeight - canvasHeight) / 2;

  return { x: marginX, y: marginY, width: canvasWidth, height: canvasHeight };
};

export const downloadFile = (url: string, name: string) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = url;
  downloadLink.download = name;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadPdfImage = (
  imageData: ImageOptions['imageData'],
  fileName: string,
  width: number,
  height: number
) => {
  const doc = new jsPDF({ unit: 'px' });
  const pdfSizes = getPdfContentAlignedSizes(
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight(),
    width,
    height
  );

  doc.addImage({
    imageData: imageData,
    x: pdfSizes.x,
    y: pdfSizes.y,
    width: pdfSizes.width,
    height: pdfSizes.height,
  });
  doc.save(`${fileName}.pdf`);
};

export const createCanvasScreenshot = async (
  canvas: HTMLCanvasElement,
  fileName: string,
  x = 0,
  y = 0,
  width = canvas.width,
  height = canvas.height,
  pixelRatio = 1
) => {
  const emptyBlob = new Blob();
  const originImageBlob = await getCanvasSnapshotBlob(canvas);
  const originImageUrl = URL.createObjectURL(originImageBlob ?? emptyBlob);
  const image = new Image();

  image.src = originImageUrl;
  image.addEventListener('load', async () => {
    const imageBlob = await getImageElementBlobContent(
      image,
      x,
      y,
      width,
      height,
      pixelRatio
    );

    const imageUrl = URL.createObjectURL(imageBlob ?? emptyBlob);

    downloadPdfImage(imageUrl, fileName, width, height);

    URL.revokeObjectURL(originImageUrl);
    URL.revokeObjectURL(imageUrl);
  });
};

// TODO: Remove after BE send actual media types
export const getDefaultMediaFile = (mediaFile: IMediaFile): IMediaFile => ({
  ...mediaFile,
  type: 'image',
});

export const getMediaFiles = (
  files: Array<string | Omit<IMediaFile, 'type'> | IMediaFile>
): IMediaFile[] => {
  if (typeof files === 'string') {
    return [
      {
        url: files,
        type: isVideo(files) ? 'video' : 'image',
      },
    ];
  }

  if (files.length) {
    return files.map((item) => ({
      ...(typeof item === 'object' ? item : {}),
      url: typeof item === 'object' ? item.url : item,
      type: isVideo(
        (item as IMediaFile).file
          ? ((item as IMediaFile)?.file as File).name
          : typeof item === 'object'
          ? item.url
          : item
      )
        ? 'video'
        : 'image',
    }));
  }

  return [];
};

export const safeSortFiles = (files: IMediaFile[], mediaOrder?: number[]) => {
  if (mediaOrder) {
    return files.slice().sort((current, next) => {
      const indexCurrent = (mediaOrder ?? []).indexOf(current.id || -1);
      const indexNext = (mediaOrder ?? []).indexOf(next.id || -1);

      return sanitizeFoundIndex(indexCurrent) - sanitizeFoundIndex(indexNext);
    });
  }

  return files;
};
