// @mapbox/mapbox-gl-draw does not provide detailed API and types access so we need to implement them
import {
  DrawCustomMode,
  DrawCustomModeThis,
  DrawFeature,
} from '@mapbox/mapbox-gl-draw';
import { Feature, Geometry, Position } from 'geojson';
import { DrawCustomFeature } from 'interfaces';

export type DrawCustomModeOverride<S = any, O = any> = DrawCustomMode<S, O> & {
  fireActionable: (this: DrawCustomModeThis, state?: S) => void;
  getSelected: (this: DrawCustomModeThis) => DrawFeature[];
  dragMove: (this: DrawCustomModeThis, state: S, e: any) => void;
  dragFeature: (this: DrawCustomModeThis, state: S, e: any, delta: any) => void;
  dragVertex: (this: DrawCustomModeThis, state: S, e: any, delta: any) => void;
  clickOnVertex: (this: DrawCustomModeThis, state: S, e: any) => void;
};

export type DrawModeFeatureCommonProperties = {
  id: string;
  active: string;
  user_subtype?: DrawFeatureSubtypes;
};

export type DrawModeCircleFeatureProperties =
  DrawModeFeatureCommonProperties & {
    user_subtype?: DrawFeatureSubtypes.CIRCLE;
    user_center: Position;
    user_measure: number;
    user_radiusInM: number;
  };

export type DrawModeCommonFeature = Feature<
  any,
  DrawModeFeatureCommonProperties
>;
export type DrawModeCircleFeature = Feature<
  Geometry,
  DrawModeCircleFeatureProperties
>;

export type MapDrawTool = MapDrawModes | MapCreateTools | MapMeasureTools;

export enum DrawFeatureSubtypes {
  LINE_STRING = 'line_string',
  POLYGON = 'polygon',
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
}

// MapDraw package internal draw modes duplicate
export enum MapDrawModes {
  draw_line_string = 'draw_line_string',
  draw_polygon = 'draw_polygon',
  draw_point = 'draw_point',
  simple_select = 'simple_select',
  direct_select = 'direct_select',
}

// MapDraw custom draw modes
export enum MapCreateTools {
  create_line_string = 'create_line_string',
  create_polygon = 'create_polygon',
  create_circle = 'create_circle',
}

export enum MapMeasureTools {
  measure_artillery = 'measure_artillery',
  measure_screenshot = 'measure_screenshot',
}

export enum DrawEventActions {
  DRAW_CANCEL = 'draw_cancel',
  DRAW_STOP = 'draw_stop',
  DRAW_CREATE = 'draw_create',
}

/**
 * featureTarget may come with empty or incomplete geometry array so it's required to be validated before processing
 */
export type DrawFeatureCommonEvent = {
  type: string;
  featureTarget: DrawFeature | DrawCustomFeature;
};
