import { ISelectOption } from 'interfaces';
import { ValueOf } from 'types';

/**
 * Restrictions on the number of characters that can be entered in the user name field
 */
export const USER_NAME_LIMIT = 100;

/**
 * Restrictions on the number of characters that can be entered in the password field
 */
export const PASSWORD_LIMIT = 20;

export enum userRoleTypes {
  USER = 'user',
  LEAD = 'lead',
}

export const featurePermission: Record<string, ValueOf<userRoleTypes>[]> = {
  'create-report': [userRoleTypes.LEAD],
  'create-client': [userRoleTypes.LEAD],
  'feature-filter-by-client': [userRoleTypes.LEAD],
  'converter-customer-reports': [userRoleTypes.LEAD],
  'delete-imagery': [userRoleTypes.LEAD],
};

export const USER_ROLE_OPTIONS: ISelectOption[] = [
  { value: 'admin', label: 'Системный администратор' },
  { value: 'user', label: 'Пользователь' },
];

export const GROUP_ROLE_OPTIONS: ISelectOption[] = [
  { value: 'admin', label: 'Администратор группы' },
  { value: 'user', label: 'Участник' },
];
