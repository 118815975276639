export interface ScreenCapturePoint {
  x: number;
  y: number;
}

export interface ScreenCaptureAreaSize {
  width: number;
  height: number;
}

export enum ScreenCaptureAreaDirections {
  RIGHT_BOTTOM = 'right-bottom',
  RIGHT_TOP = 'right-top',
  LEFT_BOTTOM = 'left-bottom',
  LEFT_TOP = 'left-top',
}
