import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { googleLabelLayer, googleLabelSource } from 'configs/map/google';
import { useAppSelector } from 'hooks';
import { BeforeIDProp } from 'interfaces';
import { showLabelsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const GoogleLabelsSource: FC<BeforeIDProp> = ({ beforeId }) => {
  const showLabels = useAppSelector(showLabelsSelector);

  if (!showLabels) {
    return null;
  }

  return (
    <Source {...googleLabelSource}>
      <Layer {...googleLabelLayer} beforeId={beforeId} />
    </Source>
  );
};
