import {
  DrawCustomMode,
  DrawCustomModeThis,
  MapMouseEvent,
} from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import {
  CIRCLE_POLYGON_VERTEX_COUNT,
  MapboxDrawCustomEvents,
} from 'constants/mapDraw';
import { DrawCircleModeState } from 'interfaces';
import { MapTouchEvent } from 'mapbox-gl';
import { DrawFeatureSubtypes, MapDrawModes, MeasureSystems } from 'types';

function onMove(
  this: DrawCustomModeThis,
  state: DrawCircleModeState,
  e: MapMouseEvent | MapTouchEvent
) {
  const center = state.polygon.properties.center;

  if (center.length) {
    const distanceInM = distance(
      point(center),
      point([e.lngLat.lng, e.lngLat.lat]),
      { units: MeasureSystems.METERS }
    );

    const distanceInKm = distanceInM / 1000;
    const circleFeature = circle(center, distanceInKm);

    state.polygon.incomingCoords(circleFeature.geometry.coordinates);
    state.polygon.properties.radiusInM = distanceInM;
  }
}

function onPress(
  this: DrawCustomModeThis,
  state: DrawCircleModeState,
  e: MapMouseEvent | MapTouchEvent
) {
  const feature = state.polygon;
  const currentCenter = feature.properties.center;

  if (!currentCenter.length) {
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
  }

  if (
    currentCenter.length &&
    (currentCenter[0] !== e.lngLat.lng || currentCenter[1] !== e.lngLat.lat)
  ) {
    feature.properties.vertexCount = CIRCLE_POLYGON_VERTEX_COUNT;
    feature.properties.hasMinimumVertexCount = true;
    this.changeMode(MapDrawModes.simple_select, {
      featureIds: [state.polygon.id],
    });
  }
}

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
const DrawCircleMode: DrawCustomMode = {
  onSetup: function (opts) {
    const polygon = this.newFeature({
      type: 'Feature',
      properties: {
        subtype: DrawFeatureSubtypes.CIRCLE,
        vertexCount: 0,
        hasMinimumVertexCount: false,
        center: [],
        radiusInM: 0,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    });

    this.clearSelectedFeatures();
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    this.addFeature(polygon);

    return {
      polygon,
    };
  },
  onClick: function (state: DrawCircleModeState, e) {
    if (e.originalEvent.button === 2) {
      return;
    }

    onPress.apply(this, [state, e]);
  },
  onTap: onPress,
  onMouseMove: function (state: DrawCircleModeState, e) {
    onMove.apply(this, [state, e]);
    this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, {
      ...e,
      featureTarget: state.polygon.toGeoJSON(),
    });
  },
  onTouchMove: function (state: DrawCircleModeState, e) {
    onMove.apply(this, [state, e]);
    this.map.fire(MapboxDrawCustomEvents.TOUCH_MOVE, {
      ...e,
      featureTarget: state.polygon.toGeoJSON(),
    });
  },
  onStop: function (state: DrawCircleModeState) {
    const featureTarget = state.polygon.toGeoJSON();

    if (state.polygon.isValid()) {
      const polygonGeoJson = state.polygon.toGeoJSON();

      this.map.fire('draw.create', {
        features: [polygonGeoJson],
      });
    } else {
      this.deleteFeature(state.polygon.id, { silent: true });
    }

    this.map.fire(MapboxDrawCustomEvents.STOP, { featureTarget });
  },
  toDisplayFeatures: function (state: DrawCircleModeState, geojson, display) {
    display(geojson);
  },
};

export { DrawCircleMode };
