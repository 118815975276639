import { DrawFeature } from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { Position } from '@turf/turf';
import { MapboxDrawConstants, MapboxDrawLib } from 'constants/mapDraw';
import { DrawFeatureSubtypes, MeasureSystems } from 'types';
import { DrawModeCircleFeature, DrawModeCommonFeature } from 'types';

const { constrainFeatureMovement } = MapboxDrawLib;

export function createVertex(
  parentId: string | number,
  coordinates: Position,
  path: any,
  selected: boolean
) {
  return {
    type: MapboxDrawConstants.geojsonTypes.FEATURE,
    properties: {
      meta: MapboxDrawConstants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected
        ? MapboxDrawConstants.activeStates.ACTIVE
        : MapboxDrawConstants.activeStates.INACTIVE,
    },
    geometry: {
      type: MapboxDrawConstants.geojsonTypes.POINT,
      coordinates: coordinates,
    },
  };
}

export function createCircleSupplementaryPoints(
  drawFeature: DrawModeCommonFeature | DrawModeCircleFeature
) {
  const { properties, geometry } = drawFeature;

  if (properties.user_subtype !== DrawFeatureSubtypes.CIRCLE) return null;

  const supplementaryPoints = [];
  const vertices = geometry.coordinates[0].slice(0, -1);

  for (
    let index = 0;
    index < vertices.length;
    index += Math.round(vertices.length / 4)
  ) {
    supplementaryPoints.push(
      createVertex(properties.id, vertices[index], `0.${index}`, false)
    );
  }

  return supplementaryPoints;
}

export function createRectangleSupplementaryPoints(
  drawFeature: DrawModeCommonFeature
) {
  const { properties, geometry } = drawFeature;

  if (properties.user_subtype !== DrawFeatureSubtypes.RECTANGLE) return null;

  const supplementaryPoints = [];
  const vertices = geometry.coordinates[0].slice(0, -1);

  supplementaryPoints.push(
    createVertex(properties.id, vertices[2], '0.2', false)
  );

  return supplementaryPoints;
}

export function moveCircleCenters(
  features: DrawFeature[],
  delta: { lng: number; lat: number }
) {
  features
    .filter(
      (feature) => feature?.properties?.subtype === DrawFeatureSubtypes.CIRCLE
    )
    .forEach((circle) => {
      const [lng, lat] = circle?.properties?.center ?? [0, 0];

      circle.setProperty('center', [lng + delta.lng, lat + delta.lat]);
    });
}

export function dragCircleVertex(state: any, e: any) {
  const center = state.feature.properties.center;
  const movedVertex = [e.lngLat.lng, e.lngLat.lat];
  const radiusInM = distance(point(center), point(movedVertex), {
    units: MeasureSystems.METERS,
  });
  const radiusInKm = radiusInM / 1000;
  const circleFeature = circle(center, radiusInKm);

  state.feature.incomingCoords(circleFeature.geometry.coordinates);
  state.feature.properties.radiusInM = radiusInM;
}

export function dragRectangleVertex(state: any, e: any) {
  const startPoint = state.feature.coordinates[0][0];
  const endPoint = [e.lngLat.lng, e.lngLat.lat];
  const incomingCoords = [
    [
      startPoint,
      [endPoint[0], startPoint[1]],
      endPoint,
      [startPoint[0], endPoint[1]],
      startPoint,
    ],
  ];

  state.feature.incomingCoords(incomingCoords);
}

export function dragCommonVertex(state: any, e: any, delta: any) {
  const selectedCoords = state.selectedCoordPaths.map((coord_path: any) =>
    state.feature.getCoordinate(coord_path)
  );

  const selectedCoordPoints = selectedCoords.map((coords: any) => ({
    type: MapboxDrawConstants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: MapboxDrawConstants.geojsonTypes.POINT,
      coordinates: coords,
    },
  }));

  const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);

  for (let i = 0; i < selectedCoords.length; i++) {
    const coord = selectedCoords[i];
    state.feature.updateCoordinate(
      state.selectedCoordPaths[i],
      coord[0] + constrainedDelta.lng,
      coord[1] + constrainedDelta.lat
    );
  }
}
