import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';

import { isMouseEvent, isTouchEvent } from 'utils';

import { ScreenCaptureAreaDirections } from './types';

export const START_ICON_CLASSNAMES = {
  [ScreenCaptureAreaDirections.RIGHT_BOTTOM]:
    'left-0 top-0 -translate-x-1/2 -translate-y-1/2',
  [ScreenCaptureAreaDirections.RIGHT_TOP]:
    'left-0 bottom-0 -translate-x-1/2 translate-y-1/2',
  [ScreenCaptureAreaDirections.LEFT_BOTTOM]:
    'right-0 top-0 translate-x-1/2 -translate-y-1/2',
  [ScreenCaptureAreaDirections.LEFT_TOP]:
    'right-0 bottom-0 translate-x-1/2 translate-y-1/2',
};

export const END_ICON_CLASSNAMES = {
  [ScreenCaptureAreaDirections.RIGHT_BOTTOM]:
    'right-0 bottom-0 translate-x-1/2 translate-y-1/2',
  [ScreenCaptureAreaDirections.RIGHT_TOP]:
    'right-0 top-0 translate-x-1/2 -translate-y-1/2',
  [ScreenCaptureAreaDirections.LEFT_BOTTOM]:
    'left-0 bottom-0 -translate-x-1/2 translate-y-1/2',
  [ScreenCaptureAreaDirections.LEFT_TOP]:
    'left-0 top-0 -translate-x-1/2 -translate-y-1/2',
};

export const getMoveCoordinates = (
  e:
    | ReactMouseEvent<HTMLDivElement, MouseEvent>
    | ReactTouchEvent<HTMLDivElement>
) => {
  if (isMouseEvent(e)) {
    return { x: e.clientX, y: e.clientY };
  }

  if (isTouchEvent(e)) {
    return { x: e.targetTouches?.[0].clientX, y: e.targetTouches?.[0].clientY };
  }

  return { x: 0, y: 0 };
};
