import { FC } from 'react';
import { labelPaint } from 'configs/map/common';
import { PREVIEW_LINES, PREVIEW_POINTS, PREVIEW_POLYGONS } from 'constants/map';
import { SymbolLayerSpecification } from 'mapbox-gl';
import { LayerPartialSpecification } from 'types';

import { getPointConfig } from '../Sources/LayersSource/configs';
import { LineSource } from '../Sources/LineSource';
import { PointSource } from '../Sources/PointSource';
import { PolygonSource } from '../Sources/PolygonSource';

interface PreviewMapObjectsSourceProps {
  points: GeoJSON.FeatureCollection;
  lines: GeoJSON.FeatureCollection;
  polygons: GeoJSON.FeatureCollection;
}

type SymbolLayerConfig = LayerPartialSpecification<SymbolLayerSpecification>;

export const PreviewMapObjectsSource: FC<PreviewMapObjectsSourceProps> = ({
  points,
  lines,
  polygons,
}) => {
  return (
    <>
      <PolygonSource
        id={PREVIEW_POLYGONS}
        data={polygons}
        mainLabelConfig={{ paint: labelPaint } as SymbolLayerConfig}
      />
      <LineSource
        id={PREVIEW_LINES}
        data={lines}
        mainLabelConfig={{ paint: labelPaint } as SymbolLayerConfig}
      />
      <PointSource
        id={PREVIEW_POINTS}
        data={points}
        mainConfig={getPointConfig()}
        mainLabelConfig={{ paint: labelPaint } as SymbolLayerConfig}
      />
    </>
  );
};
