import React, { useCallback } from 'react';
import { mapBackingLayers, mapUpperLayers } from 'constants/mapTypes';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { DatePicker } from 'components/ui';

import { formatDate } from '../../../../utils';
import EntityFieldsGroup from '../../../ui/EntityCard/EntityFieldsGroup';
import SidebarWindowWithTabs from '../../../ui/SidebarWindowWithTabs';
import MapTypeElement from '../Logistic/MapTypeElement';

import './style.scss';

const MapTypes = () => {
  const dispatch = useAppDispatch();
  const mapTypes = useAppSelector(typesSelector);

  const handleSetMapTypes = useCallback(
    (name: string) => () => {
      const currentType = mapTypes[name];

      const setMapTypeActive = (
        name: string,
        active: boolean,
        backing?: true
      ) => {
        const backingTypes = Object.fromEntries(
          Object.entries(mapTypes)
            .filter(([key, item]) => item.type === 'style')
            .map(([key, item]) => [key, { ...item, active: false }])
        );
        const mapTypesToSet = backing
          ? { ...mapTypes, ...backingTypes }
          : mapTypes;

        dispatch(
          settingsActions.setTypes({
            ...mapTypesToSet,
            [name]: {
              ...currentType,
              active,
            },
          })
        );
      };

      if (currentType.type === 'style') {
        if (currentType.active) return;
        setMapTypeActive(name, true, true);
        return;
      }

      setMapTypeActive(name, !currentType.active);
    },
    [mapTypes]
  );

  const handleSetFireDate = useCallback(
    (date: Date) => {
      const fireType = mapTypes['fire'];
      dispatch(
        settingsActions.setTypes({
          ...mapTypes,
          fire: {
            ...fireType,
            data: formatDate(date),
          },
        })
      );
    },
    [mapTypes]
  );

  const mapBacking = (
    <div className="p-4">
      {mapBackingLayers.map((item, index) => (
        <MapTypeElement
          key={index}
          index={index}
          title={item.label}
          description={item.description}
          name={item.name}
          active={mapTypes[item.name].active}
          onSwitch={handleSetMapTypes(item.name)}
          preview={item.preview}
        />
      ))}
    </div>
  );

  const upperLayers = (
    <div className="p-4">
      <EntityFieldsGroup title="Высоты" contentClassName="gap-2">
        {mapUpperLayers.height.map((item, index) => (
          <MapTypeElement
            key={index}
            index={index}
            title={item.label}
            description={item.description}
            name={item.name}
            active={mapTypes[item.name].active}
            onSwitch={handleSetMapTypes(item.name)}
            icon={item.icon}
            preview={item.preview}
            opacitySlider
          />
        ))}
      </EntityFieldsGroup>
      <EntityFieldsGroup title="Пожары" contentClassName="gap-2">
        <MapTypeElement
          key={3}
          index={3}
          title={mapUpperLayers.fire.label}
          description={mapUpperLayers.fire.description}
          name={mapUpperLayers.fire.name}
          active={mapTypes[mapUpperLayers.fire.name].active}
          onSwitch={handleSetMapTypes(mapUpperLayers.fire.name)}
          icon={mapUpperLayers.fire.icon}
        />
        {mapTypes.fire.active && (
          <div className="map-types__fire-date">
            <p className="tpg-text-six">Выберите дату пожарa</p>
            <DatePicker
              minDate={dayjs().subtract(7, 'days').toDate()}
              maxDate={dayjs().toDate()}
              selected={dayjs(mapTypes.fire.data).toDate()}
              onChange={handleSetFireDate}
            />
          </div>
        )}
      </EntityFieldsGroup>
      <EntityFieldsGroup title="Спутниковые слои" contentClassName="gap-2">
        {mapUpperLayers.imagery.map((item, index) => (
          <MapTypeElement
            key={index}
            index={index}
            title={item.label}
            description={item.description}
            name={item.name}
            active={mapTypes[item.name].active}
            onSwitch={handleSetMapTypes(item.name)}
            preview={item.preview}
            opacitySlider
          />
        ))}
      </EntityFieldsGroup>
    </div>
  );

  return (
    <SidebarWindowWithTabs
      tabs={[mapBacking, upperLayers]}
      tabOptions={['Подложка', 'Верхний слой']}
    />
  );
};

export default MapTypes;
