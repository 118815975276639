import { Layer, Source } from 'react-map-gl';
import { sovietMilitaryMapSource } from 'configs/map/sovietMilitaryMap';
import {
  SATELLITE_STATIC_LAYER,
  SOVIET_MILITARY_MAP_UPPER_LAYER,
} from 'constants/map';
import { useAppSelector } from 'hooks';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity } from 'utils';

export const SovietMilitaryMapSource = () => {
  const mapTypes = useAppSelector(typesSelector);

  const showMilitaryMap = mapTypes.sovietMilitaryMapUpper.active;

  if (!showMilitaryMap) {
    return null;
  }

  return (
    <Source {...sovietMilitaryMapSource} id="soviet-military-upper-source">
      <Layer
        id={SOVIET_MILITARY_MAP_UPPER_LAYER}
        source="soviet-military-upper-source"
        beforeId={SATELLITE_STATIC_LAYER}
        type="raster"
        paint={{
          'raster-opacity': getProperOpacity(mapTypes.sovietMilitaryMapUpper),
        }}
      />
    </Source>
  );
};
