import { SourceProps } from 'react-map-gl';
import { SOVIET_MILITARY_MAP_LAYER } from 'constants/map';
import { RasterLayerSpecification, StyleSpecification } from 'mapbox-gl';

export const sovietMilitaryMapSource: SourceProps = {
  type: 'raster',
  tiles: ['https://proxy.nakarte.me/http/88.99.52.155/tmg/{z}/{x}/{y}'],
  name: 'soviet-military-source',
  id: 'soviet-military-source',
  maxzoom: 20,
  tileSize: 256,
};
export const sovietMilitaryMapLayer: RasterLayerSpecification = {
  id: SOVIET_MILITARY_MAP_LAYER,
  source: 'soviet-military-source',
  type: 'raster',
  minzoom: 0,
};
export const sovietMilitaryMapStyle: StyleSpecification = {
  version: 8,
  sprite: `${window.location.origin}/images/sprites/map-icons-sprite`,
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {
    'soviet-military-source': sovietMilitaryMapSource,
  },
  layers: [sovietMilitaryMapLayer],
};
