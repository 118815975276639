import MapboxDraw from '@mapbox/mapbox-gl-draw';

export const VALID_LINE_STRING_MIN_VERTEX_COUNT = 2;
export const VALID_POLYGON_MIN_VERTEX_COUNT = 3;

export const CIRCLE_POLYGON_VERTEX_COUNT = 64;
export const SQUARE_POLYGON_VERTEX_COUNT = 4;

export const MapboxDrawConstants = MapboxDraw.constants;
export const MapboxDrawLib = MapboxDraw.lib;
export const MapboxDrawModes = MapboxDraw.modes;
export const MapboxDrawEvents = MapboxDraw.constants.events;
export const MapboxDrawCustomEvents = {
  STOP: 'draw.stop',
  DRAG_VERTEX: 'draw.drag_vertex',
  DRAG_FEATURE: 'draw.drag_feature',
  DRAG_MOVE: 'draw.drag_move',
  MOUSE_MOVE: 'draw.mouse_move',
  TOUCH_MOVE: 'draw.touch_move',
  CLICK: 'draw.click',
  TAP: 'draw.tap',
};
