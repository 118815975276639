import { createContext, MutableRefObject } from 'react';
import { Map } from 'mapbox-gl';

export interface IMapRefContext {
  mapRef: MutableRefObject<Map | null>;
}

export const MapRefContext = createContext<IMapRefContext | undefined>(
  undefined
);

export const MapPreviewRefContext = createContext<IMapRefContext | undefined>(
  undefined
);
