import {
  MapboxDrawConstants,
  MapboxDrawCustomEvents,
  MapboxDrawLib,
  MapboxDrawModes,
} from 'constants/mapDraw';
import { DrawFeatureSubtypes } from 'types';
import {
  DrawCustomModeOverride,
  DrawModeCircleFeature,
  DrawModeCommonFeature,
} from 'types';

import {
  createCircleSupplementaryPoints,
  createRectangleSupplementaryPoints,
  dragCircleVertex,
  dragCommonVertex,
  dragRectangleVertex,
  moveCircleCenters,
} from './utils';

const DirectSelectModeOverride = Object.assign(
  {},
  MapboxDrawModes.direct_select as DrawCustomModeOverride
);

const { createSupplementaryPoints, moveFeatures } = MapboxDrawLib;

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
DirectSelectModeOverride.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  moveCircleCenters(this.getSelected(), delta);

  state.dragMoveLocation = e.lngLat;

  const featureTarget = state.feature.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.DRAG_FEATURE, {
    ...e,
    featureTarget,
  });
};

DirectSelectModeOverride.dragVertex = function (state, e, delta) {
  switch (state.feature.properties.subtype) {
    case DrawFeatureSubtypes.CIRCLE:
      dragCircleVertex(state, e);
      break;
    case DrawFeatureSubtypes.RECTANGLE:
      dragRectangleVertex(state, e);
      break;
    default:
      dragCommonVertex(state, e, delta);
  }

  const featureTarget = state.feature.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.DRAG_VERTEX, {
    ...e,
    featureTarget,
  });
};

DirectSelectModeOverride.toDisplayFeatures = function (
  state,
  drawFeature: DrawModeCommonFeature | DrawModeCircleFeature,
  display
) {
  if (state.featureId === drawFeature.properties.id) {
    const supplementaryPointsOptions: any = {
      map: this.map,
      midpoints: true,
      selectedPaths: state.selectedCoordPaths,
    };

    const supplementaryPoints =
      (drawFeature.properties.user_subtype === DrawFeatureSubtypes.CIRCLE &&
        createCircleSupplementaryPoints(drawFeature)) ||
      (drawFeature.properties.user_subtype === DrawFeatureSubtypes.RECTANGLE &&
        createRectangleSupplementaryPoints(drawFeature)) ||
      createSupplementaryPoints(drawFeature, supplementaryPointsOptions);

    drawFeature.properties.active = MapboxDrawConstants.activeStates.ACTIVE;

    display(drawFeature);
    supplementaryPoints?.forEach(display);
  } else {
    drawFeature.properties.active = MapboxDrawConstants.activeStates.INACTIVE;
    display(drawFeature);
  }

  this.fireActionable(state);
};

export { DirectSelectModeOverride };
